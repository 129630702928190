/* colors */
/*@blue:#415f8c;*/
/* PADDING */
/* TOOLBAR */
/*Toolbar colors*/
/*Toolbar button colors*/
/*Toolbar form elements*/
/*Toolbar dropdown menu*/
.altai-admin-toolbar md-menu-bar {
  padding: 0 20px 0 180px;
  color: white;
}
.altai-admin-toolbar md-toolbar {
  min-height: 0;
  background-color: transparent;
}
.altai-admin-toolbar md-menu-bar button {
  font-size: 12px;
  padding: 0 10px;
  margin: 0;
  border: 0;
  background-color: transparent;
  height: 40px;
}
.altai-admin-toolbar md-menu-bar button:hover {
  background-color: #18417b;
}
.altai-admin-toolbar a.md-button.md-default-theme:not([disabled]):hover,
.altai-admin-toolbar a.md-button:not([disabled]):hover,
.altai-admin-toolbar .md-button.md-default-theme:not([disabled]):hover,
.altai-admin-toolbar .md-button:not([disabled]):hover {
  background-color: #a1c4ff;
}
md-menu-item > .md-button,
.md-button {
  font-size: 12px;
}
md-menu-content.md-default-theme,
md-menu-content {
  background-color: #3b83ff;
  color: white;
}
md-menu-content.md-default-theme,
md-menu-item,
md-menu-content md-menu-item {
  background-color: #3b83ff;
  color: white;
}
select,
button,
textarea,
input {
  font-size: 14px;
}
